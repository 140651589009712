import React, {useState, useEffect} from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"
import moment from "moment";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Parts from '../components/parts';
import _ from 'lodash';
import HeroImage from "../components/HeroImage/heroimage";
import { withPrefix } from "gatsby"
import MDRender from "../components/mdrender"
const isBrowser = typeof document !== "undefined"

import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'
import ServiceMenu from '../components/servicemenu';

import { navigateCorrectPage, initialiseAccessible, AccessibleButton, accessibleHandleClick} from '../components/accessible/accessible';


export default function simpleSolutionPage({ path,data }) {

    let mapPagedata = _.merge(  _.pick( data.page, ['id','date','title','description','section','page','slug','options','keywords']),
                                _.pick( data.page.body.data, ['body']),
                                {image: data.page.image.localFile }
                              )
    let partsData = _.orderBy( data.page.parts.map( item => {
                  //console.debug( item );
                  return _.merge( _.pick( item, ['id','title','order','page','layouttype','partId','anchor','options']),
                                  { image: (item.image) ? item.image.localFile : null },
                                  { text: (item.text && item.text.data ) ? ( item.text.data.text ) : "" })
                }),["order"],["asc"]  )
    let siteMetadata = data.site.siteMetadata;
    let pagesData = _.map( data.pages.nodes,'path');

    //**************************************************************************


    const [accessible, setAccessible] = useState(initialiseAccessible({path: path, accessibleFolder: siteMetadata.accessibleFolder }));


  //**************************************************************************

    return (
      <Layout header="Page" >
        <Seo  title={ mapPagedata.title } descripton={mapPagedata.descripton}  keywords={mapPagedata.keywords} />
        <div className="site-container" id={mapPagedata.slug}>
          <div className="container">
                {mapPagedata.image ? ( <GatsbyImage image={mapPagedata.image.childImageSharp.gatsbyImageData} alt="image" objectFit="cover" objectPosition="50% 50%"  /> ) : ( <div className="no-image"></div> )}
                <div className="details">
                  <h2 className="title">{mapPagedata.title}</h2>
                  <h3 className="description">{mapPagedata.description}</h3>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col">
                        <span className="date">
                          <i className="fas fa-calendar-alt"></i>{" "}{moment(mapPagedata.date,  "YYYY-MM-DD").format("LL")}
                        </span>
                      </div>
                      <div className="col-auto">
                        {AccessibleButton({ accessible: accessible, path: path, accessibleFolder: siteMetadata.accessibleFolder, pages: pagesData }) }
                      </div>
                    </div>
                  </div>
                  <div>
                    <MDRender mdstring={mapPagedata.body} />
                  </div>
                  <Parts key="Parts" anchor="parts" parts={partsData}/>
                  <ServiceMenu  key="ServiceMenu" title="Our Products and Services" ></ServiceMenu>
                </div>
          </div>
        </div>
      </Layout>
    );
}

export const pageQuery = graphql`
query ($id: String!) {
  page: strapiSolution(id: {eq: $id }) {
    id
    body {
      data {
        id
        body
      }
    }
    date
    title
    page
    description
    section
    slug
    options {
        useHero
        background
    }
    parts {
      id
      order
      page
      title
      text {
        data {
          text
        }
      }
      layouttype
      partId
      anchor
      options {
        parallax
        height
        centre
        centretitle
        margin
        filter
      }
      image {
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
    }
    image {
      id
      localFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }

  site {
    siteMetadata {
      accessibleFolder
    }
  }

  pages: allSitePage {
    nodes {
      path
    }
  }

}
`;
